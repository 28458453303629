var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"m-0 justify-content-center"},[_c('b-col',{staticClass:"content-header",attrs:{"cols":"9"}},[_c('h2',{staticClass:"content-header-title float-left m-0 pr-1 mr-1"},[_vm._v(" "+_vm._s(_vm.$route.meta.pageTitle)+" ")]),_c('div',{staticClass:"content-header-search mr-1"},[_c('h3',{staticClass:"m-0"},[_vm._v(" "+_vm._s((_vm.collaboratorForm.civility == 0 ? "M. " : _vm.collaboratorForm.civility == 1 ? "Mme " : "") + _vm.collaboratorForm.firstName + " " + _vm.collaboratorForm.lastName)+" ")])]),_c('div',{staticClass:"content-header-actions d-flex"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"24"},on:{"click":function($event){return _vm.cancel()}}})],1)])],1),(_vm.isLoadingCollaborator)?_c('div',{staticClass:"content-loader-center m-0 h-100"},[_vm._m(0)]):_c('b-row',{staticClass:"content-scrollable-sticky m-0 h-100 justify-content-center"},[_c('b-col',{staticClass:"content-scrollable-sticky-main",attrs:{"cols":"9"}},[_c('validation-observer',{ref:"formCreateCollaborator"},[_c('b-form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"my-0",attrs:{"id":"general"}},[_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Civilité","label-for":"civility"}},[_c('validation-provider',{attrs:{"name":"Civility"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"civility","state":errors.length > 0 ? false : null,"reduce":function (type) { return type.value; },"options":_vm.optionCivility},model:{value:(_vm.collaboratorForm.civility),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "civility", $$v)},expression:"collaboratorForm.civility"}})]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Nom *","label-for":"lastName"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastName","state":errors.length > 0 ? false : null},model:{value:(_vm.collaboratorForm.lastName),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "lastName", $$v)},expression:"collaboratorForm.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Prénom *","label-for":"firstName"}},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstName","state":errors.length > 0 ? false : null},model:{value:(_vm.collaboratorForm.firstName),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "firstName", $$v)},expression:"collaboratorForm.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.collaboratorForm.email),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "email", $$v)},expression:"collaboratorForm.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Téléphone principal","label-for":"phoneNumber"}},[_c('validation-provider',{attrs:{"name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('## ## ## ## ##'),expression:"'## ## ## ## ##'"}],attrs:{"id":"phoneNumber","state":errors.length > 0 ? false : null},model:{value:(_vm.collaboratorForm.phoneNumber),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "phoneNumber", $$v)},expression:"collaboratorForm.phoneNumber"}})]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Téléphone secondaire","label-for":"secondaryPhoneNumber"}},[_c('validation-provider',{attrs:{"name":"secondaryPhoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('## ## ## ## ##'),expression:"'## ## ## ## ##'"}],attrs:{"id":"secondaryPhoneNumber","state":errors.length > 0 ? false : null},model:{value:(_vm.collaboratorForm.secondaryPhoneNumber),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "secondaryPhoneNumber", $$v)},expression:"collaboratorForm.secondaryPhoneNumber"}})]}}])})],1)],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Métier *","label-for":"jobId"}},[_c('validation-provider',{attrs:{"name":"JobId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"jobId","state":errors.length > 0 ? false : null,"reduce":function (type) { return type.id; },"options":_vm.jobsList},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [[_vm._v(" Aucun résultat trouvé ")]]},proxy:true}],null,true),model:{value:(_vm.collaboratorForm.jobId),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "jobId", $$v)},expression:"collaboratorForm.jobId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Type de contrat *","label-for":"contractId"}},[_c('validation-provider',{attrs:{"name":"ContractRef","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"contractId","state":errors.length > 0 ? false : null,"reduce":function (type) { return type.id; },"options":_vm.professionalContractTypesList},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [[_vm._v(" Aucun résultat trouvé ")]]},proxy:true}],null,true),model:{value:(_vm.collaboratorForm.contractId),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "contractId", $$v)},expression:"collaboratorForm.contractId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Matricule","label-for":"matricule"}},[_c('validation-provider',{attrs:{"name":"Matricule"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"matricule","state":errors.length > 0 ? false : null},model:{value:(_vm.collaboratorForm.matricule),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "matricule", $$v)},expression:"collaboratorForm.matricule"}})]}}])})],1)],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Numéro de sécurité sociale","label-for":"socialSecurityNumber"}},[_c('validation-provider',{attrs:{"name":"socialSecurityNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"socialSecurityNumber","state":errors.length > 0 ? false : null},model:{value:(_vm.collaboratorForm.socialSecurityNumber),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "socialSecurityNumber", $$v)},expression:"collaboratorForm.socialSecurityNumber"}})]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Lié à un utilisateur","label-for":"user"}},[_c('v-select',{attrs:{"id":"contractId","reduce":function (type) { return type.id; },"options":_vm.usersListFiltered,"label":"firstName","filter":_vm.searchUser},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [[_vm._v(" Aucun résultat trouvé ")]]},proxy:true},{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(_vm._s(firstName + ' ' + lastName))]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
return [_vm._v(_vm._s(firstName + ' ' + lastName))]}}]),model:{value:(_vm.collaboratorForm.userId),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "userId", $$v)},expression:"collaboratorForm.userId"}})],1)],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Commentaire","label-for":"commentaire"}},[_c('vs-textarea',{staticStyle:{"min-height":"140px","font-size":"1rem"},attrs:{"height":"140px"},model:{value:(_vm.collaboratorForm.comments),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "comments", $$v)},expression:"collaboratorForm.comments"}})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12"}},[_c('small',{staticClass:"cursor-pointer text-primary",on:{"click":_vm.displayFormComplement}},[_vm._v(_vm._s(_vm.displayMore ? "- Masquer les informations complémentaires" : "+ Afficher les informations complémentaires")+" ")])])],1)],1),(_vm.displayMore)?_c('div',{attrs:{"id":"complement"}},[_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"address'"}},[_vm._v("Adresse")]),_c('validation-provider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-typeahead-bootstrap',{staticClass:"mb-1 not-autocomplete",attrs:{"type":"text","state":errors.length > 0 ? false : null,"id":"address","ieCloseFix":false,"data":_vm.autocompleteAddressData,"serializer":function (item) { return item.properties.label; },"showAllResults":true,"placeholder":"Rechercher une adresse ou saisissez : Rue et n° de rue","autocomplete":"nope","aria-autocomplete":"nope"},on:{"input":function($event){return _vm.autocompleteAddressSource()},"hit":_vm.autocompleteAddressSelected},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"item"},[_c('small',[_vm._v(_vm._s(data.properties.name))]),_c('span',[_vm._v(_vm._s(data.properties.postcode + " " + data.properties.city))])])]}}],null,true),model:{value:(_vm.collaboratorForm.address),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "address", $$v)},expression:"collaboratorForm.address"}})]}}],null,false,1596784276)})],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"addressComplement"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"addressComplement","state":errors.length > 0 ? false : null,"placeholder":"Bâtiment, Appartement.."},model:{value:(_vm.collaboratorForm.addressComplement),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "addressComplement", $$v)},expression:"collaboratorForm.addressComplement"}})]}}],null,false,531632954)})],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"zipcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"zipcode","state":errors.length > 0 ? false : null,"placeholder":"Code postal"},model:{value:(_vm.collaboratorForm.zipCode),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "zipCode", $$v)},expression:"collaboratorForm.zipCode"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,1059569383)})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"9"}},[_c('validation-provider',{attrs:{"name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"city","state":errors.length > 0 ? false : null,"placeholder":"Ville"},model:{value:(_vm.collaboratorForm.city),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "city", $$v)},expression:"collaboratorForm.city"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,3788162629)})],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"country","state":errors.length > 0 ? false : null,"placeholder":"Pays"},model:{value:(_vm.collaboratorForm.country),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "country", $$v)},expression:"collaboratorForm.country"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,1972353220)})],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Distance maximum","label-for":"travelMax"}},[_c('validation-provider',{attrs:{"name":"travelMax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"zipCode","state":errors.length > 0 ? false : null},model:{value:(_vm.collaboratorForm.travelMax),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "travelMax", $$v)},expression:"collaboratorForm.travelMax"}})]}}],null,false,3973679148)})],1)],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"address'"}},[_vm._v("Coordonnées GPS")])]),_c('b-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"latitude"}},[_c('b-form-input',{staticClass:"mb-0",attrs:{"id":"latitude","placeholder":"Latitude"},model:{value:(_vm.collaboratorForm.latitude),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "latitude", $$v)},expression:"collaboratorForm.latitude"}})],1)],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"longitude"}},[_c('b-form-input',{staticClass:"mb-0",attrs:{"id":"longitude","placeholder":"Longitude"},model:{value:(_vm.collaboratorForm.longitude),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "longitude", $$v)},expression:"collaboratorForm.longitude"}})],1)],1)],1)],1):_vm._e()])],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between mt-2",staticStyle:{"border-top":"1px solid #e2e2e2","padding-top":"0.5rem"}},[_c('div',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.cancel()}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" Annuler ")],1),(_vm.collaboratorForm.id)?_c('b-button',{staticClass:"btn-icon ml-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.archiveCollaboratorLocal(
                    _vm.collaboratorForm.id,
                    _vm.collaboratorForm.firstName +
                      ' ' +
                      _vm.collaboratorForm.lastName
                  )}}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon"}})],1):_vm._e()],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.create}},[(_vm.collaboratorForm.id)?_c('feather-icon',{attrs:{"icon":"SaveIcon"}}):_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.collaboratorForm.id ? "Modifier" : "Ajouter")+" ")],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center flex-center"},[_c('div',{staticClass:"loading-bg-inner"},[_c('div',{staticClass:"loader"},[_c('div',{staticClass:"outer"}),_c('div',{staticClass:"middle"}),_c('div',{staticClass:"inner"})])]),_c('div',{staticClass:"mt-5"},[_c('br'),_c('br'),_c('br'),_vm._v(" Chargement des détails du collaborateur... ")])])}]

export { render, staticRenderFns }